import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled, { createGlobalStyle } from 'styled-components';
import { animated } from 'react-spring';

import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';

import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { SiteContext } from '../../../utils/context/SiteContext';
import { Seo } from '../../../utils/seo/Index';
import { PlayButton } from '../../ui/play-button/Index';
import { Hero } from '../../ui/hero/Index';
import { VideoPlayer } from '../../ui/video-player/Index';
import { Modal } from '../../ui/modal/Modal';
import { useMutation } from '@apollo/client';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { createLeadMutation } from '../../../../client/__graphql__/mutations';

import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { useForm } from '../../../utils/hooks/useForm';
import { useClickoutside } from '../../../utils/hooks/useClickoutside';

import { FormError } from '../../ui/form/FormError';
import { Form, FormRow } from '../../ui/form/Form';
import { Input } from '../../ui/form/Input';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';

import { AdvertisingPageSection } from '../../ui/advertising/AdvertisingPageSection';
import { CloseIcon } from '../../ui/icons/Close';
import { Logo } from '../../ui/logo/office/Index';

interface IRoute {
  broker: string;
  path: string;
}

const basis = [
  {
    id: 1,
    text: 'Vi inngår en oppdragsavtale',
    hotspot:
      'Oppdragsavtalen kan sendes rett til din mailboks, og du signerer enkelt og raskt med din BankID.'
  },
  {
    id: 2,
    text: 'Finansiering avklares',
    hotspot: 'Vi hjelper deg med å avklare finansiering.'
  },
  {
    id: 3,
    text: 'Du finner selv aktuelle eiendommer og sender de til oss',
    hotspot:
      'Aktuelle objekter kan være markedsført gjennom hvilken som helst megler i Norge. Bruk gjerne vår app, «Mitt Eiendomskjøp», til å søke etter eiendom. Denne laster du enkelt ned via App Store eller Google Play.'
  },
  {
    id: 4,
    text: 'Vi gjennomgår salgsoppgaver før visning',
    hotspot:
      'Vi bistår i vurderingen av aktuelle eiendommer og ser blant annet på prisantydning opp mot område, kvaliteter ved eiendommen og eventuelle svakheter. Mot et tillegg blir vi gjerne med deg på visning.'
  },
  {
    id: 5,
    text: 'Vi gir deg råd i budprosessen',
    hotspot:
      'Vi leverer konkrete og taktiske råd underveis, så du kan ta bedre avgjørelser. Vår oppgave er å jobbe for at du skal få kjøpt til en best mulig pris.'
  },
  {
    id: 6,
    text: 'Vi gir deg råd før kontraktsmøtet',
    hotspot:
      'Vi går igjennom kjøpskontrakten og vedlegg sammen, og passer på at kontrakten er fullstendig og rettferdig. Det er viktig at begge parter er innforstått med hva kontrakten sier.'
  },
  {
    id: 7,
    text: 'Vi gir deg råd i forbindelse med overtakelsen',
    hotspot:
      'Vi forbereder deg på hvilke ting du må være klar over ved overtakelse. Ønsker du at vi deltar, kan vi gjøre det mot et tillegg. Der sørger vi for at dine rettigheter blir ivaretatt, riktig avlesning av strøm og vannforbruk.'
  },
  {
    id: 8,
    text: 'Vi kan hjelpe begge parter med oppgjøret',
    hotspot:
      'Normalt vil selgers megler stå for oppgjøret. Skulle derimot begge parter ønske det, kan vår oppgjørsavdeling håndtere transaksjonen mellom partene.'
  }
];

const premium = [
  {
    id: 9,
    text: 'Vi inngår en oppdragsavtale',
    hotspot:
      'Oppdragsavtalen kan sendes rett til din mailboks og du signerer enkelt og raskt med din BankID.'
  },
  {
    id: 10,
    text: 'Vi kartlegger dine behov og ønsker',
    hotspot:
      'Vi gjør oss kjent med dine preferanser som beliggenhet, pris og andre krav du stiller til en aktuell eiendom.'
  },
  {
    id: 11,
    text: 'Finansiering avklares',
    hotspot: 'Vi hjelper deg med å avklare finansiering.'
  },
  {
    id: 12,
    text: 'Vi søker etter eiendommer for deg',
    hotspot:
      'Vi bruker vår kunnskap og kontaktnett for å identifisere eiendommer som tilfredsstiller dine preferanser. Det inkluderer også eiendommer som ikke er lagt ut for salg.'
  },
  {
    id: 13,
    text: 'Vi markedsfører kjøpsinteresse mot eiere av eiendom',
    hotspot:
      'Vi kontakter eiere av eiendommer som er aktuelle, men ikke lagt ut for salg. Når vi gjør det på vegne av en konkret, potensiell kjøper, er det lettere for selger å starte en prosess.'
  },
  {
    id: 14,
    text: 'Vi gjennomfører befaring for deg om ønskelig',
    hotspot:
      'Dersom eier av eiendommen er interessert i å selge, besøker vi eiendommen for å forsikre oss om at den matcher dine preferanser. Du vil først få en tilbakemelding om vårt inntrykk av eiendommen og selgers vurderinger med tanke på et eventuelt salg.'
  },
  {
    id: 15,
    text: 'Vi vurderer eiendom, salgsoppgave og takst',
    hotspot:
      'Vi bistår i vurderingen av aktuelle eiendommer, og ser blant annet på prisantydning opp mot område, kvaliteter ved eiendommen og eventuelle svakheter. Dersom selger ikke har megler, lager vi en lovpålagt salgsoppgave.'
  },
  {
    id: 16,
    text: 'Vi deltar på visning',
    hotspot:
      'Basert på den informasjonen vi har, legger vi en plan for hva vi skal se etter og undersøke nærmere på visning. Dersom det ikke foreligger en tilstandsrapport, kan vi ordne det.'
  },
  {
    id: 17,
    text: 'Vi deltar i forhandlinger og budprosess',
    hotspot:
      'Vi leverer konkrete og taktiske råd slik at du kan ta bedre avgjørelser. Vi deltar i forhandlinger direkte med selger eller selgers megler.'
  },
  {
    id: 18,
    text: 'Vi gir råd om og utformer kjøpskontrakt',
    hotspot:
      'Dersom selger ikke har megler, utformer vi kjøpskontrakten mellom partene. Vi passer uansett på at kontrakten er fullstendig og rettferdig. Det er viktig at begge parter er innforstått med hva kontrakten sier.'
  },
  {
    id: 19,
    text: 'Vi deltar i kontraktsmøtet',
    hotspot:
      'Vi er tilstede og bistår deg for å sikre en trygg og ryddig prosess i kontraktsmøtet. Dersom selger har egen megler, vil vi tilby å delta eller gi deg råd i forkant av kontraktsmøtet.'
  },
  {
    id: 20,
    text: 'Vi deltar på overtakelsen',
    hotspot:
      'Vi deltar på overtakelsen og sørger for at dine rettigheter blir ivaretatt, riktig avlesning av strøm og vannforbruk. Dette gir deg som kjøper en trygghet i den siste fasen av eiendomskjøpet.'
  },
  {
    id: 21,
    text: 'Vi kan hjelpe begge parter med oppgjøret',
    hotspot:
      'Dersom selger har egen megler, vil selgers megler normalt håndtere oppgjøret mellom dere. Dersom selger ikke har egen megler, vil vår oppgjørsavdeling kunne håndtere transaksjonen.'
  }
];

const HoverLi = React.memo(
  (props: {
    id: number;
    text: string;
    hotspot: string;
    showHotspot: any;
    setShowhotspot: any;
  }) => {
    const ref = React.useRef(null);

    const show = () => {
      props.setShowhotspot(props.id);
    };

    const close = React.useCallback(() => {
      props.setShowhotspot(null);
    }, [props.setShowhotspot]);

    useClickoutside({
      ref: ref,
      onClickOutside: () => {
        close();
      }
    });

    return (
      <li ref={ref}>
        {props.text}
        <QM onClick={show} />
        {props.showHotspot === props.id && (
          <HotSpot>
            {props.hotspot}{' '}
            <button className="close-button" onClick={() => close()}>
              <CloseIcon />
            </button>
          </HotSpot>
        )}
      </li>
    );
  }
);

const HotSpot = styled.div`
  position: absolute;
  top: calc(-57px);
  left: 0px;
  min-width: 100%;
  padding: 12px 30px 12px 12px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.9);
  color: rgb(0, 0, 0);
  opacity: 1;
  pointer-events: all;
  transition: opacity 250ms ease-in-out 0s;
  z-index: 10;

  .close-button {
    position: absolute;
    top: 8px;
    right: 5px;
    border: none;
    outline: none;
    background: none;
    appearance: none;

    div {
      width: 12px;
      height: 12px;
      svg {
        width: inherit;
        height: inherit;
        path {
          fill: #000;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

const QM = styled.div`
  background-image: url('https://pm2019.reeltime.no/public/images/qm-03.e37b83c1.png');
  background-size: contain;
  width: 14px;
  height: 14px;
  cursor: pointer;
  left: 10px;
  position: relative;
  display: inline-block;
`;

const Kjop: React.FC<RouteComponentProps<IRoute>> = ({ location, match }) => {
  const { header, routeConfig } = React.useContext(SiteContext);

  const [showVideo, setShowVideo] = React.useState(false);
  const [showVideoBoligbytte, setShowBoligbytteVideo] = React.useState(false);
  const [showHotspot, setShowhotspot] = React.useState(false);
  const [posted, setPosted] = React.useState(false);

  React.useEffect(() => {
    header.setForceBackground(true);
  }, []);

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const [formHeight, setFormHeight] = React.useState(null);

  const formRef = React.useCallback((node) => {
    if (node !== null) {
      let height = node.getBoundingClientRect().height;
      setFormHeight(height);
    }
  }, []);

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
        success
      }`
    }
  });

  const [mail, { loading, error }] = useMutation<ILeadResponse, ILeadInput>(
    MAIL_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead && lead.mail && lead.mail.success) {
          setPosted(true);
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Ola Nordmann',
        value: '',
        label: 'Navn'
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: '1234',
        value: '',
        label: 'Postnummer *',
        labelAfter: '(Skriv postnummeret der du ønsker å kjøpe)',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: '00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields = getFields(fields);
      mail({
        variables: {
          input: {
            ...preparedFields,
            referer: `${location.pathname}${location.search}`,
            leadType: 'EPOST',
            mailType: 3
          }
        }
      });
    }
  });

  return (
    <>
      <FooterOverrideStyles />
      <Seo
        title="PrivatMegleren KJØP"
        description="La en av våre eiendomsmeglere bistå deg i kjøpet. Se alle våre eiendommer til salgs."
        image="https://cdn.reeltime.no/pm_assets/kjop/Bg_topp_KJOP.jpg"
        imageTitle="/verdivurdering-hytte-fritidseiendom-salg-av-eiendom"
        url={location.pathname}
      />
      <Page style={{ ...pageFadeIn }}>
        <Hero
          className="flex hero"
          size="cover"
          imageUrl="https://cdn.reeltime.no/pm_assets/kjop/Bg_topp_KJOP.jpg"
          opacity={0.4}
          alt="bilde"
        >
          <HeroContent>
            <KjopLogo />
            <Heading tag="h1" center={true}>
              Vurderer du å kjøpe eiendom?
            </Heading>
            <Paragraph center={true} className="ingress">
              Med tjenesten KJØP har du megler med deg i hvert trekk i
              forbindelse med kjøp av eiendom. Vi kartlegger behovene dine nøye
              og matcher med både ulike markeder og våre egne nettverk for å
              finne det som du vil være interessert i. Vi hjelper med
              gjennomgang og vurdering av salgsoppgaver og tilstandsrapporter,
              vurdering av prisantydninger til taktisk rådgivning gjennom
              budrunden.
            </Paragraph>
            <Paragraph center={true} className="ingress">
              La oss bidra til at ditt kjøp av eiendom blir en god opplevelse –
              fra salgsoppgave til overtakelse.
            </Paragraph>
          </HeroContent>
        </Hero>
        <GridSection>
          <GridContainer>
            <Heading tag="h2" center={true} className="underline">
              Stå sterkere
            </Heading>
            <Paragraph center={true} className="narrow-paragraph">
              Stå sterkere i ditt kjøp av eiendom med PrivatMegleren på din side
              gjennom hele prosessen. Få taktisk rådgivning, hjelp og
              anbefalinger fra erfarne meglere som fremmer dine interesser i
              markedet. Fyll ut skjemaet under, så kontakter vi deg og finner ut
              hvordan vi kan hjelpe deg å oppnå drømmen om eiendom utifra dine
              ønsker og forutsetninger.
            </Paragraph>
            <Container style={{ paddingTop: '30px' }}>
              <PlaySection>
                <PlayButton
                  className="play-button"
                  onClick={() => setShowVideo(true)}
                />
              </PlaySection>
              <Modal
                id="portals"
                noBodyScroll={true}
                showModal={showVideo}
                overlay={true}
                showCloseButton={true}
                onClickClose={() => setShowVideo(false)}
                onClickOutside={() => setShowVideo(false)}
              >
                <VideoPlayer
                  type="vimeo"
                  url="https://player.vimeo.com/video/515185604?autoplay=1"
                />
              </Modal>
            </Container>
          </GridContainer>
        </GridSection>
        <PackageSection>
          <div className="container">
            <InfoCard>
              <Heading tag="h2" center={true} className="underline">
                Basis
              </Heading>
              <Paragraph>
                La en av Norges beste eiendomsmeglere bistå deg med strategi og
                taktikk når du skal kjøpe eiendom.
              </Paragraph>
              <HoverList>
                {basis.map((e) => (
                  <HoverLi
                    key={e.id}
                    text={e.text}
                    hotspot={e.hotspot}
                    id={e.id}
                    showHotspot={showHotspot}
                    setShowhotspot={setShowhotspot}
                  />
                ))}
              </HoverList>
            </InfoCard>
            <InfoCard>
              <Heading tag="h2" center={true} className="underline">
                Premium
              </Heading>
              <Paragraph>
                Fortell oss hva du ser etter i din neste eiendom, og la en av
                Norges beste eiendomsmeglere gjøre resten.
              </Paragraph>
              <HoverList>
                {premium.map((e) => (
                  <HoverLi
                    key={e.id}
                    text={e.text}
                    hotspot={e.hotspot}
                    id={e.id}
                    showHotspot={showHotspot}
                    setShowhotspot={setShowhotspot}
                  />
                ))}
              </HoverList>
            </InfoCard>
          </div>
        </PackageSection>
        <Container style={{ paddingTop: '30px', paddingBottom: '60px' }}>
          <Section
            style={{
              maxWidth: '770px',
              textAlign: 'center',
              margin: '0 auto'
            }}
          >
            <Heading tag="h2" center={true}>
              Følge hele veien
            </Heading>
            <Paragraph
              center={true}
              style={{ maxWidth: '500px', margin: '0 auto' }}
            >
              Stå sterkere i ditt eiendomskjøp med PrivatMegleren på din side
              gjennom hele prosessen. Få taktisk rådgivning, hjelp og
              anbefalinger fra erfarne meglere som fremmer dine interesser i
              markedet. Fyll ut skjemaet under, så kontakter vi deg og finner ut
              hvordan vi kan hjelpe deg å oppnå eiendomsdrømmen utifra dine
              ønsker og forutsetninger.
            </Paragraph>
          </Section>
          <FormWrapper style={fadeOut}>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              noValidate
              style={{ padding: '30px 0' }}
            >
              {error &&
                error.graphQLErrors.map((error, index) => (
                  <FormError key={`error_${index}`} message={error.message} />
                ))}
              {fields.map((item, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item?.type}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
              <Paragraph>
                Jeg anmoder PrivatMegleren om å kontakte meg på telefon, sms
                eller e-post i forbindelse med kjøpsmeglingsoppdrag.
              </Paragraph>
              <ButtonGroup>
                <Button
                  type="primary"
                  colorTheme="gold"
                  disabled={loading}
                  loading={loading}
                >
                  Kontakt meg
                </Button>
                <ButtonLink
                  as="a"
                  href={routeConfig?.personvern.url}
                  className="privacy-link-mobile"
                >
                  Personvernpolicy
                </ButtonLink>
              </ButtonGroup>
            </Form>
          </FormWrapper>
          <FormWrapper style={{ ...fadeIn }}>
            <FormSuccess style={{ height: `${formHeight}px` }}>
              <Heading tag="h2" center={true}>
                <span
                  style={{
                    color: '#e8c893',
                    fontSize: '48px',
                    fontWeight: 400
                  }}
                >
                  Takk for din henvendelse
                </span>
              </Heading>
              <Paragraph center={true}>
                Vi tar kontakt så snart som mulig.
              </Paragraph>
            </FormSuccess>
          </FormWrapper>
        </Container>

        <AdvertisingPageSection
          logoWithManualLabel
          name="Boligbytte"
          logoUrl="https://cdn.reeltime.no/pm_assets/logo/pm_kjop.svg"
          readmoreLink={routeConfig?.boligbytte.url || ''}
          videoUrl="https://player.vimeo.com/video/515287324?color=e8c893&title=0&byline=0&portrait=0&controls=1"
        >
          <Heading tag="h2" center={true}>
            Har du flytteplaner?
          </Heading>
          <Paragraph
            center={true}
            style={{ maxWidth: '500px', margin: '0 auto' }}
          >
            La hele flytteprosessen bli tatt hånd om av profesjonelle! Fra vask,
            styling og klargjøring av eiendommen før visning, til pakking,
            mellomlagring, flytting og overføringer av forskjellige avtaler.
          </Paragraph>
        </AdvertisingPageSection>
      </Page>
    </>
  );
};

export default Kjop;

const KjopLogo: React.FC = () => {
  return (
    <StyledBoligbytteLogoWrapper>
      <StyledBoligbytteLogo
        alt="pm-logo-kjop"
        style={{ color: 'white' }}
        src="https://cdn.reeltime.no/pm_assets/logo/pm_kjop.svg"
      />
    </StyledBoligbytteLogoWrapper>
  );
};

const HoverList = styled.ul`
  list-style-type: none;
  margin: 1em 0;
  padding: 0;
  li {
    position: relative;
    margin: 1em 0;
  }
`;

const InfoCard = styled.div`
  display: inline-block;
  width: 100%;
  padding: 30px;
  vertical-align: top;
  text-align: center;
  color: #fff;
  background: ${({ theme }) => theme.colors.burgundy_card};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
`;

const StyledBoligbytteLogoWrapper = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
`;

const StyledBoligbytteLogo = styled.img`
  position: relative;
  max-width: 300px;
  width: 100%;
  margin: 0 auto 10px auto;
  object-fit: contain;
`;

const PlaySection = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 300px;
  padding: 20px 0 20px 0;
  margin: 20px auto 20px auto;
  display: flex;
  justify-content: center;
  background-image: url('https://cdn.reeltime.no/pm_assets/kjop/kjop_video.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

const Page = styled(animated.div)`
  width: 100%;
  padding-top: 72px;

  .hero {
    height: auto;

    .hero-content {
      height: auto;
      padding: 100px 0;
    }
  }

  .kjop-heading {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 28px;
    max-width: 80%;
  }
`;

const FormWrapper = styled(animated.div)``;

const FormSuccess = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeroContent = styled(animated.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 960px;
  padding: 0 50px;

  h1 {
    margin-bottom: 0;
    width: 100%;
    word-break: break-word;
  }

  h2 {
    width: 100%;
    word-break: break-word;
  }
`;

const GridSection = styled.section`
  display: block;
  background: ${({ theme }) => theme.colors.burgundy};
  padding: 50px 0;
`;

const GridContainer = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 30px;

  .narrow-paragraph {
    max-width: 400px;
    margin: 0 auto;
  }

  .play-button {
    svg {
      circle {
        stroke: #fff;
      }
      path {
        fill: #fff;
      }
    }
  }
`;

const PackageSection = styled.section`
  display: block;
  width: 100%;
  padding: 100px 0;
  background: url('https://cdn.reeltime.no/pm_assets/kjop/Bg_niva_KJOP.jpg')
    no-repeat;
  background-size: cover;
  background-position: center;

  @media all and (max-width: 1000px) {
    padding: 50px 0;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    padding: 0 30px;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;

    @media all and (max-width: 1000px) {
      padding: 0 20px;
      grid-template-columns: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 50px;
    }
  }
`;

const FooterOverrideStyles = createGlobalStyle`
  .footer {
    border-top: none;
    margin-top: 0;
  }
  `;
